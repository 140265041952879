import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

export default function Component() {
  const ip = "https://sureshinterior.in/"
  const portfolioName = {
    "tv-units": [
        "Frame Light TV Wall Unit",
        "Skyline TV Panel",
        "Imperial Marble TV Stand",
        "Tranquility Base TV Unit",
        "Metropolitan TV Suite",
        "Reflections TV Unit",
        "Contemporary Media Center",
        "Elegant Glass Display Unit",
        "Rustic Farmhouse TV Console"
    ],
    "dressing-areas": [
        "Modern Elegance Vanity",
        "Classic Chic Makeup Station",
        "Contemporary Glam Vanity",
        "Urban Luxe Dressing Area",
        "Boutique Style Dresser",
        "Vintage Glam Dressing Table",
        "Minimalist Wood Makeup Station",
        "Art Deco Inspired Vanity",
        "Scandinavian Style Dressing Unit"
    ],
    "study-table": [
        "Sleek Futurist Study Desk",
        "Warm Minimalist Workspace",
        "Urban Compact Study Nook",
        "Classic Modernist Study Area",
        "Contemporary Urban Workstation",
        "Ergonomic Executive Desk",
        "Industrial Style Study Table",
        "Rustic Wooden Writing Desk",
        "Tech-Savvy Study Setup"
    ],
    "kitchen": [
        "Urban Chic Kitchen",
        "Luxurious Monochrome Kitchen",
        "Classic Elegance Kitchen",
        "Contemporary Minimalist Kitchen",
        "Rustic Retreat Kitchen",
        "Futuristic Metro Kitchen",
        "Bright Open Concept Kitchen",
        "Elegant Farmhouse Kitchen",
        "Sleek Contemporary Kitchen"
    ],
    "bedroom": [
        "A Minimalist Bedroom Retreat",
        "A Modern Bedroom Oasis",
        "A Minimalist Bedroom Design",
        "A Bedroom Collection",
        "A Minimalist Bedroom Gallery",
        "A Bedroom Inspiration",
        "Cozy Bohemian Bedroom",
        "Luxurious Master Suite",
        "Chic Urban Bedroom Design"
    ],
    "commercial-interior": [
        "Sleek Spaces",
        "Functional and Stylish",
        "Minimalist Marvels",
        "Modern Workplace",
        "Efficient and Elegant",
        "Creative Collaborative Spaces",
        "Innovative Retail Design",
        "Professional Office Layout",
        "Contemporary Business Hub"
    ],
    "pooja-unit": [
        "Soulful Sanctuary",
        "Spiritual Haven",
        "Divine Decor",
        "Custom-Crafted Devotion",
        "Personalized Prayer Nook",
        "Tranquil Temple",
        "Sacred Space for Meditation",
        "Artistic Pooja Mandir",
        "Traditional Worship Area"
    ],
    "wardrobe": [
        "Fashion Fortress",
        "Smart Storage",
        "Your Personal Stylist",
        "Tailored to You",
        "The Ultimate Closet",
        "Efficient Elegance",
        "Walk-in Wardrobe Wonder",
        "Stylish Built-in Closets",
        "Modular Wardrobe Solutions"
    ],
};

  const portfolioSections = [
    {
      id: "tv-units",
      title: "TV Units",
      images: Array(9).fill(0).map((_, i) => `${ip}/images/tv-unit/tv-unit${i + 1}.jpg`),
    },
    {
      id: "dressing-areas",
      title: "Dressing Areas",
      images: Array(9).fill(0).map((_, i) => `${ip}/images/dressing-areas/dressing-areas${i + 1}.jpg`),
    },
    {
      id: "study-table",
      title: "Study Table",
      images: Array(9).fill(0).map((_, i) => `${ip}/images/study-table/study-table${i + 1}.jpg`),
    },
    {
      id: "kitchen",
      title: "Kitchen",
      images: Array(9).fill(0).map((_, i) => `${ip}/images/kitchen/kitchen${i + 1}.jpg`),
    },
    {
      id: "bedroom",
      title: "Bedroom",
      images: Array(9).fill(0).map((_, i) => `${ip}/images/bedroom/bedroom${i + 1}.jpg`),
    },
    {
      id: "commercial-interior",
      title: "Commercial Interior",
      images: Array(9).fill(0).map((_, i) => `${ip}/images/commercial-interior/commercial-interior${i + 1}.jpg`),
    },
    {
      id: "pooja-unit",
      title: "Pooja Unit",
      images: Array(9).fill(0).map((_, i) => `${ip}/images/pooja-unit/pooja-unit${i + 1}.jpg`),
    },
    {
      id: "wardrobe",
      title: "Wardrobe",
      images: Array(9).fill(0).map((_, i) => `${ip}/images/wardrobe/wardrobe${i + 1}.jpg`),
    },
  ]

  const location = useLocation();
  console.log(location.state);
  useEffect (() => {
    if (location.state?.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [location.state])

  return (
    <div className="min-h-screen bg-amber-50 text-amber-900 mt-16">
      <Helmet>
        <title>Portfolio | Suresh Interior</title>
        <meta name="description" content="Explore the portfolio of Suresh Interior, featuring designs for TV Units, Dressing Areas, Study Tables, Kitchens, Bedrooms, Commercial Interiors, Pooja Units, and Wardrobes." />
        <meta property="og:title" content="Portfolio | Suresh Interior" />
        <meta property="og:description" content="A showcase of our finest interior design projects across various categories, including custom-made TV Units, Kitchens, and more." />
        <meta property="og:image" content={`${ip}/images/portfolio-banner.jpg`} />
        <meta property="og:url" content="https://sureshinterior.in/portfolio" />
        <link rel="canonical" href="https://sureshinterior.in/portfolio" />
      </Helmet>
      <main className="container mx-auto p-4 space-y-16">
        {portfolioSections.map((section) => (
          <div key={section.id} name={section.id} className="scroll-mt-20" id={section.id}>
            <h2 className="text-3xl font-bold mb-6">{section.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {section.images.map((image, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-200 hover:scale-105"
                >
                  <img
                    src={image}
                    alt={`${section.title} ${index + 1}`}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">
                      {portfolioName[section.id][index] || `${section.title} ${index + 1}`}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </main>
    </div>
  );
}