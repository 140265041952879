import React, { useEffect} from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Carousel from "@ui/carousel";
import MovingBrand from "@component/movingBrand";
import { Whatsapp } from "@ui/whatsapp";
import { Facebook } from "@ui/facebook";
import { useNavigate } from "../../node_modules/react-router-dom/dist/index";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";


export default function Component() {
  const ip = "https://sureshinterior.in/"

  const images = [
    `${ip}/interior1.jpg`,
    `${ip}/interior2.jpg`,
    `${ip}/interior3.jpg`,
    `${ip}/Bedroom.jpg`,
  ];

  const portfolio = [
    {
      title: "TV Units",
      id: "tv-units",
      description: "Our TV unit designs combine functionality and style, offering sleek storage solutions tailored to your living space.",
      image: `${ip}/Tv.jpg`,
    },
    {
      title: "Dressing Areas",
      id: "dressing-areas",
      description: "Our dressing areas are designed to blend elegance and practicality, providing stylish storage solutions for your personal space.",
      image: `${ip}/Dressing.jpg`,
    },
    {
      title: "Study Table",
      id: "study-table",
      description: "Our custom study tables are designed to offer both functionality and style, creating an inspiring workspace tailored to your needs.",
      image: `${ip}/Study.jpg`,
    },
    {
      title: "Kitchen",
      id: "kitchen",
      description: "Our kitchen designs focus on blending style with functionality, creating a space that's both efficient and aesthetically pleasing.",
      image: `${ip}/Kitchen.jpg`,
    },
    {
      title: "Bedroom",
      id: "bedroom",
      description: "Our bedroom designs create a serene and stylish sanctuary, tailored to your personal comfort and aesthetic preferences.",
      image: `${ip}/Bedroom.jpg`,
    },
    {
      title: "Commercial Interior",
      id: "commercial-interior",
      description: "Our commercial designs create functional spaces that reflect your brand's identity.",
      image: `${ip}/interior5.jpg`,
    },
    {
      title: "Pooja Unit",
      id: "pooja-unit",
      description: "A pooja unit is a dedicated space or altar in homes or temples for conducting religious rituals and worship. It typically includes idols, sacred items, and offerings, creating an environment for spiritual practice and devotion.",
      image: `${ip}/Pooja.jpg`,
    },
    {
      title: "Wardrobe",
      id: "wardrobe",
      description: "A wardrobe is a tall, freestanding piece of furniture used for storing clothes, accessories, and personal items. It typically features a combination of hanging space, shelves, and drawers, helping to keep garments organized and easily accessible.",
      image: `${ip}/Wardrobe.jpg`,
    },
  ];

  const location = useLocation();
  console.log(location.state);
  useEffect (() => {
    if (location.state?.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [location.state])

  const navigate = useNavigate();
  const scrollToServices = (section) => {
    navigate('/portfolio', { state: { scrollTo: section } });
  };

  return (
    <div id="home-page" className="relative">
      <Helmet>
        <title>Suresh Interior | Home Decor & Interior Solutions</title>
        <meta name="description" content="Suresh Interior is a leading interior design company offering home and commercial design solutions across India." />
        <meta name="keywords" content="interior design, home decor, commercial interior, custom furniture, Bangalore, Chennai, Hyderabad" />
        <meta property="og:title" content="Suresh Interior | Home Decor & Interior Solutions" />
        <meta property="og:description" content="Transform your living or workspace with Suresh Interior. We offer unique and custom interior design solutions." />
        <meta property="og:image" content={`${ip}/interior1.jpg`} />
        <meta property="og:url" content="https://sureshinterior.in/" />
        <link rel="canonical" href="https://sureshinterior.in/" />
      </Helmet>
      
      <div className="fixed z-[500] bottom-9 bg-[rgb(27,186,19)] right-5 w-10 h-10 flex items-center justify-center animate-bounce shadow-[0_0_25px_25px_rgb(27,186,19)]">
        <a href="https://api.whatsapp.com/send?phone=917848901171&text=Hello%20Suresh Interior,%20I%20am%20looking%20forward%20to%20designing%20my%C2%A0space%C2%A0with%C2%A0you."><Whatsapp /></a>
      </div>

      <div className="fixed z-[500] bottom-9 bg-primary opacity-100 left-5 w-10 h-10 flex items-center justify-center animate-bounce shadow-[0_0_25px_25px_rgb(15,150,208)]">
        <a href="https://www.facebook.com/profile.php?id=100089504312054&mibextid=ZbWKwL=917848901171&text=Hello%20Sowiance,%20I%20am%20looking%20forward%20to%20designing%20my%C2%A0space%C2%A0with%C2%A0you."><Facebook /></a>
      </div>

      <section id="hero-section" className="h-mobile-menu mt-16">
        <Carousel className="h-full" items={images} />
      </section>

      <section id="about-us" className='px-4 py-16 bg-beige text-text md:px-[10vw]'>
        <h2 className='text-center text-6xl font-serif mb-3'>About Us</h2>
        <div className=" flex flex-col items-center md:flex-row gap-8">
          <div className="md:w-1/2">
            <p id="para1" className='text-lg mb-4 text-justify'>
            Founded in 2015, Suresh Interior is a premier interior decor company that has quickly grown into one of India's most trusted names in home and commercial design. With a passion for creating unique and functional spaces, the company has completed numerous projects across major cities like Mumbai, Delhi, Bangalore, and Hyderabad. Specializing in modern, minimalist, and luxury designs, Suresh Interior transforms homes, offices, and retail spaces into visually stunning environments.
            </p>
            <p id="para2" className='text-lg mb-5 text-justify'>
            Their experienced team collaborates closely with clients to ensure that each project reflects their individual taste and lifestyle. Today, Suresh Interior is known for blending aesthetics with practicality, offering custom solutions that exceed client expectations.
            </p>
            <Link to='/service'>
              <div id="cta-button" className='px-6 py-3 w-fit bg-cta text-primary font-semibold text-lg rounded-lg shadow-md hover:bg-hover transition-colors duration-300 cursor-pointer'>Explore Our Services</div>
            </Link>
            </div>
          <img src={images[1]} alt="" className='rounded-xl md:w-1/2'/>
        </div>
      </section>

      <section id="portfolio" className="bg-primary py-10">
        <h2 className="text-center text-6xl font-serif mb-3">Portfolio</h2>
        <div id="portfolio-container" className="flex flex-wrap flex-row justify-center">
          {portfolio.map((item, index) => (
            <div
              key={index}
              id={`portfolio${index}`}
              className="flex flex-col mb-5 w-96 m-10 bg-[#fff] rounded-xl md:w-1/3 lg:w-1/4"
            >
              <img
                src={item.image}
                alt={item.title}
                className="rounded-xl object-cover w-full h-64 transition-transform duration-300 ease-in-out transform hover:scale-105"
              />
              <h3 className="px-5 mt-5">{item.title}</h3>
              <p className="px-5 mb-5 text-justify">{item.description}</p>
                <button onClick={() => scrollToServices(item.id)} className="w-full text-center my-4 px-6 py-3 bg-cta text-primary font-semibold text-lg rounded-lg shadow-md hover:bg-hover transition-colors duration-300 cursor-pointer">
                  View Details
                </button>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}