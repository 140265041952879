import React from 'react'
import { Helmet } from 'react-helmet';
import { Mail, MapPin, Phone } from 'lucide-react';

const Contact = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-16 bg-cta text-primary">
      <Helmet>
        <title>Contact Us - Suresh Interior</title>
        <meta name="description" content="Contact Suresh Interior for custom interior design solutions in Bangalore. Reach us via phone, email, or visit our office." />
        <meta name="keywords" content="Contact, Interior Design, Bangalore, Suresh Interior" />
        <meta property="og:title" content="Contact Suresh Interior - Interior Design Solutions in Bangalore" />
        <meta property="og:description" content="Get in touch with Suresh Interior for top-quality residential and commercial interior design services." />
        <meta property="og:url" content="https://sureshinterior.in/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://sureshinterior.in/images/contact-og-image.jpg" />
      </Helmet>
    <div className="container mx-auto px-4 py-8 ">
      <h1 className="text-4xl font-bold text-center mb-12">CONTACT US</h1>
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-3xl font-semibold mb-4">Suresh Interior</h2>
          <p className=" text-gray-600 mb-6">
            Suresh Interior, we are one of the growing unique design studio based in Bangalore for both
            residential and commercial projects, mainly extending and providing end-to-end, one-stop interior design
            solutions.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <div className="flex items-start mb-4">
              <MapPin className="w-5 h-5 mt-1 mr-2" />
              <p className="text-sm">Sy.No.27/2, Near Ravishankar School, Kachamaranahalli, Varthur Hobil, Bangalore- 560102<br /> GSTN: 29CCHPR2477K1ZV</p>
            </div>
            <div className="flex items-center mb-4">
              <Mail className="w-5 h-5 mr-2" />
              <a href="mailto:@kumavatahariram1.in" className="text-sm">
                kumavataharirama1@gmail.com
              </a>
            </div>
            <div className="flex items-center">
              <Phone className="w-5 h-5 mr-2" />
              <a href="tel:+7848901171/7665469961" className="text-sm">
                +91-7848901171/7665469961
              </a>
            </div>
          </div>
          <form className="space-y-4">
            <input placeholder="Your Name" />
            <input type="email" placeholder="Your Email" />
            {/* <input placeholder="Subject" /> */}
            <textarea placeholder="Message" />
            <button className="w-full">Send Message</button>
          </form>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Contact
