import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
export default function ServicesGrid() {
  const services = [
    {
      title: "Modular Kitchen",
      description:
        "Our consultants and interior designers in Bangalore office are highly professional and friendly. Discuss with them about your concerns and ideas regarding your furnishing requirements. They would present you with all the different possibilities and help you choose one that best suits your budget. We will extend our full support and resources to make your project unique. There is utmost transparency when it comes to our price, design selection or choice of materials. Being the most reliable home interior design company in Bangalore, D’LIFE avails its services in all major locations in the city. This includes Koramangala, Sarjapur Road, Malleswaram, White Field, Indira Nagar, Rajajinagar, J.P. Nagar, Honnenahalli, Krishnarajapura, Marthahalli etc. Customer satisfaction is our highest priority. You can expect 100% transparency and responsibility in our works and contracts",
      image: "living space.jpg",
    },
    {
      title: "Modular Bedroom",
      description:
        "Home interior design focuses on creating aesthetically pleasing and functional living spaces tailored to personal style. It blends elements like furniture, color schemes, lighting, and textures to enhance the ambiance of a home. Thoughtful interior design maximizes space while reflecting the homeowner's personality and lifestyle. A well-designed interior balances comfort, beauty, and practicality to create a harmonious living environment.",
      image: "HomeInterior.jpg",
    },
  ];

  const service = [
    "RESIDENTIAL & COMMERCIAL INTERIORS",
    "TURNKEY SOLUTIONS",
    "FURNISHING SOLUTIONS",
    "LOOSE FURNITURE",
    "HOME DECORS & FLOORING",
    "HOME OFFICE SOLUTIONS",
  ];
   const ip = "https://sureshinterior.in/"
  const images = [
    `${ip}/service1.jpg`,
    `${ip}/service2.jpg`
  ];
  
  return (

    <div className="mt-16 h-screen">
      <Helmet>
        <title>Our Services - Suresh Interior</title>
        <meta name="description" content="Explore the wide range of interior design services offered by Suresh Interior. From modular kitchens to home office solutions, we provide end-to-end furnishing solutions tailored to your needs." />
        <meta name="keywords" content="Interior Design, Modular Kitchen, Modular Bedroom, Furnishing Solutions, Bangalore" />
        <meta property="og:title" content="Our Services - Suresh Interior" />
        <meta property="og:description" content="Discover our interior design services, including modular kitchen and bedroom solutions. We provide tailored services for residential and commercial spaces in Bangalore." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${ip}/images/og-image-services.jpg`} />
        <meta property="og:url" content="https://sureshinterior.in/services" />
      </Helmet>
      <div className="max-w-7xl  mx-4">
        <h1 className="text-4xl font-bold text-center mb-12">
          <span className="inline-block border-b-2 border-white pb-2">
            OUR SERVICES
          </span>
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 bg-cta text-[white] py-3">
          {service.map((s, index) => (
            <div
              key={index}
              className="text-center p-6 border border-gray-700 rounded-lg hover:bg-gray-800 transition-colors duration-300"
            >
              <h2 className="text-xl font-semibold">{s}</h2>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-12 mx-4">
        <h2 className="text-3xl font-bold mb-6">Our Approach to Interior Design</h2>
        {services.map((service, index) => (
          <div
            className={`grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8 ${
              index % 2 !== 0 ? "sm:flex-row-reverse" : ""
            }`}
            key={index}
          >
            {/* Description */}
            <div className="flex flex-col justify-center">
              <p className="text-justify">{service.description}</p>
            </div>

            {/* Image */}
            <div className="w-full h-auto flex">
              <img
                src={images[index]}
                alt={service.title}
                className="w-full object-cover h-64 sm:h-80 md:h-96"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
